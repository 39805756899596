::-webkit-scrollbar {
    width: 6px;  /* Width of the vertical scrollbar */
    height: 6px; /* Height of the horizontal scrollbar */
}

/* Styling for the scrollbar thumb (the part you drag) */
::-webkit-scrollbar-thumb {
    background-color: #a0a0a0; /* Thumb color */
    border-radius: 4px;        /* Rounded corners for the thumb */
}

/* Styling for the scrollbar track (the part the thumb slides within) */
::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Track color */
    border-radius: 4px;        /* Rounded corners for the track */
}
/* 
.MuiList-root .MuiListItem-root .MuiButton-root {
    color: #333 !important;
} */


@font-face {
    font-family: 'Poppins-Regular';
    src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}